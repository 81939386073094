
import { defineComponent } from 'vue';
import MainNav from '../../components/layout/TheMainNav.vue';
import TheButton from '../../components/ui/native/AppButton.vue';

export default defineComponent({
  components: {
    MainNav,
    TheButton,
  },
});

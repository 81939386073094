<template>
  <header>
    <main-nav></main-nav>
  </header>

  <section class="intro">
    <div class="content">
      <div class="intro">

        <div class="left">
          <h1>About us</h1>
          <span>Agni will satisfy your curiosity for web page availability.</span>
          <span class="learn-more">Learn More
          <svg enable-background="new 0 0 490 490" version="1.1" viewBox="0 0 490 490" xml:space="preserve" xmlns="http://www.w3.org/2000/svg">
            <path d="m52.8 311.3c-12.8-12.8-12.8-33.4 0-46.2 6.4-6.4 14.7-9.6 23.1-9.6s16.7 3.2 23.1 9.6l113.4 113.4v-345.8c0-18 14.6-32.7 32.7-32.7 18 0 32.7 14.6 32.7 32.7v345.8l113.2-113.4c12.8-12.8 33.4-12.8 46.2 0s12.8 33.4 0 46.2l-169.1 169.1c-6.1 6.1-14.4 9.6-23.1 9.6s-17-3.4-23.1-9.6l-169.1-169.1z"/>
          </svg>
          </span>
        </div>

        <img src="~@/assets/img/about/who.png" alt="who">
      </div>
    </div>
  </section>

  <section>
    <div class="content">
      <div class="explanation">

        <div class="left">
          <p>
            <b>The idea behind Agni was rather simple—we wanted to develop an application that would efficiently measure a website’s performance. We were determined to come up with a tool that would measure how fast and accessible a webpage is from different locations—without the necessity of implementing complex (and often costly) corporate solutions.</b>
          </p>
          <p>As IT professionals passionate about new technologies, we came up with a project where web automation, performance testing and monitoring could create a new paradigm. As a team of three, we came up with Agni, a tool that does just that—and then some.</p>
        </div>

        <img src="~@/assets/img/about/elipse.png" alt="elipse">
      </div>

      <p class="blue">
        Our mission is to make Agni accessible to everybody. Our strategy involves creating user-friendly plugins supported within content management systems. Currently, we support WordPress, but we’re actively working on implementing other platforms, as well.
      </p>
    </div>
  </section>

  <section class="dark">
    <div class="content">
      <span>Agni is free. Why not give a try?</span>
      <span>It only takes less than three minutes to set it up.</span>
      <the-button color="green" text="Get Started"></the-button>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import MainNav from '../../components/layout/TheMainNav.vue';
import TheButton from '../../components/ui/native/AppButton.vue';

export default defineComponent({
  components: {
    MainNav,
    TheButton,
  },
});
</script>

<style lang="scss">
main {

  header {
    section {
      padding-top: 68px;
    }
  }

    @media (max-width: 950px) {
      section.intro {
        padding-bottom: 0;
      }
    }

  div.intro {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .left {
      max-width: 330px;

      span {
        margin: 20px 0 40px 0;
      }

      .learn-more {
        display: flex;
        align-items: center;
        gap: 15px;

        svg {
          width: 15px;
          fill: #5828ff;
        }
      }
    }

    img {
      max-width: 600px;
      width: 100%;

      @media (max-width: 950px) {
        margin-top: 40px;
      }
    }
  }

  .explanation{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    .left {
      max-width: 730px;

      p:nth-of-type(2) {
        margin-top: 30px;
      }
    }

    img {
      max-width: 380px;
      max-height: 380px;
      margin-top: -100px;

      @media (max-width: 1130px) {
        display: none;
      }
    }
  }

    p.blue {
      font-size: 34px;
      color: #5828FF;
      margin-top: 100px;
      font-weight: bold;
      max-width: 1000px;
    }

    section.dark {
        background: url("~@/assets/img/about/tablet.png") no-repeat center right -150px / 40%,
        transparent linear-gradient(132deg, #5828ff 0%, #807cf4 100%) 0% 0%
        no-repeat padding-box;

        .content {
          span {
            font-size: 38px;
            max-width: 450px;
            font-weight: bold;
            margin: 20px 0;

            &:nth-of-type(2){
              color: #A5A2F6;
            }
          }
        }
    }
}
</style>
